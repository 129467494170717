import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import media from '../../../utils/media'
import TopSection from '../../molecules/TopSection'
import LaunchButton from '../../atoms/LaunchButton'
import BackgroundImage from '../../molecules/BackgroundImage'
import TopSectionContent from '../../molecules/TopSectionContent'

const BULLETS = [
  'Un site dédié aux locaux professionnels',
  'Des agents spécialisés pour vous accompagner',
  'Accédez à des milliers d’acheteurs partout en France'
]

const StyledBackground = styled(BackgroundImage)`
  z-index: -1;
  display: none;

  ${media.greaterThan('xl')`
    display: block;
    height: 700px;
    top: 245px;
  `}
`

const StyledButton = styled(LaunchButton)`
  width: fit-content;

  ${media.lessThan('lg')`
    width: 100%;
  `}
`

const Button = () => {
  return (
    <StyledButton
      link='/nos-annonces/deposer-une-annonce'
      title="Déposer votre annonce"
      background='brightOrange' />
  )
}

const SellSearch = () => {
  const data = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "sell/top-section.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <TopSection component={() =>
      <StyledBackground alt='Vendre un local professionnel' data={data} />}>
      <TopSectionContent
        title='Vendre un local professionnel n’a jamais été aussi simple'
        bullets={BULLETS}
        Button={Button} />
    </TopSection>
  )
}

export default SellSearch
