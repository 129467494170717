import React from 'react'
import { get } from 'lodash'
import { useStaticQuery, graphql } from 'gatsby'

import ServicePresentation from '../ServicePresentation'

const sellData = {
  alt: 'Vendez votre local professionnel',
  title: 'Vendez votre local professionnel',
  content: `De cette façon, vous pouvez rendre votre annonce visible facilement à des milliers d’acheteurs dans toute la France.<br/>
  Et si vous avez besoin d’être accompagné, nos experts sont à votre disposition pour prendre en charge de A à Z tout le processus de vente de votre local.`,
  subtitle: 'Pour vendre vos locaux professionnels, nous mettons à votre disposition notre plateforme d’annonces dédiées aux professions libérales.'
}

const SellService = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "sell/service.webp"}) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
        )
      }
    }
  }`)

  return (
    <ServicePresentation
      alt={get(sellData, 'alt')}
      data={placeholderImage}
      title={get(sellData, 'title')}
      content={get(sellData, 'content')}
      subtitle={get(sellData, 'subtitle')} />
  )
}

export default SellService
