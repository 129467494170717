import React from 'react'
import PropTypes from 'prop-types'

import SEO from '../../components/atoms/Seo'
import Footer from '../../components/organisms/Footer'
import Navbar from '../../components/organisms/Navbar'
import HomeAds from '../../components/organisms/HomeAds'
import Categories from '../../components/organisms/Categories'
import SellSearch from '../../components/organisms/SellSearch'
import SellExperts from '../../components/organisms/SellExperts'
import SellService from '../../components/organisms/SellService'
import SiteSection from '../../components/atoms/SiteSection'
// import HomeReviews from '../../components/organisms/HomeReviews'
import SellDelegate from '../../components/organisms/SellDelegate'
import InlineBlockPresentation from '../../components/organisms/InlineBlockPresentation'

const TITLE = 'Vendre un local professionnel'
const DESCRIPTION = 'Vendre votre local professionnel'

const sellCategories = [{
  link: '/nos-annonces/vendre',
  title: 'Vente Cabinet Médical',
  image: 'medical'
}, {
  link: '/nos-annonces/vendre',
  title: 'Vente Cabinet Para-Médical',
  image: 'para-medical'
}, {
  link: '/nos-annonces/vendre',
  title: 'Vente Local 2 pièces',
  image: 'local-t2'
}, {
  link: '/nos-annonces/vendre',
  title: 'Vente Local 3 pièces',
  image: 'local-t3'
}, {
  link: '/nos-annonces/vendre',
  title: 'Vente Local Paris',
  image: 'paris'
}, {
  link: '/nos-annonces/vendre',
  title: 'Vente Local Lyon',
  image: 'lyon'
}, {
  link: '/nos-annonces/vendre',
  title: 'Vente Local Montpellier',
  image: 'montpellier'
}, {
  link: '/nos-annonces/vendre',
  title: 'Vente Local Nantes',
  image: 'nantes'
}]

const sellBlocs = [{
  html: 'Trouver Mon Local Pro est le 1er site à rassembler les offres de locaux à destination des professions libérales. Mettez votre bien en vente auprès de milliers de professionnels en quelques minutes',
  link: '/nos-annonces/deposer-une-annonce',
  title: 'Une plateforme dédiée',
  subtitle: 'aux locaux professionnels',
  image: 'sell-1',
  button: 'Déposer mon annonce'
}, {
  html: 'Vendre votre local professionnel peut demander beaucoup de temps et nécessite une très bonne connaissance de ce marché.</br>Nos experts sont là pour vous accompagner en commençant par estimer gratuitement la valeur de votre local',
  link: '/nos-annonces/demande-accompagnement',
  title: 'Vendre mon local',
  subtitle: 'avec un Pro',
  image: 'sell-2',
  button: 'Faire estimer mon local'
}]

const SellPage = ({ location }) => {
  return (
    <>
      <SEO
        title={TITLE}
        description={DESCRIPTION} />
      <Navbar location={location} />
      <SellSearch />
      <SiteSection id='services-presentation' margin='100px 0 0 0'>
        <InlineBlockPresentation
          blocs={sellBlocs}
          title='Vendez votre futur local seul ou accompagné' />
      </SiteSection>
      <SiteSection id='votre-futur-local' margin='120px 0 0 0'>
        <SellService />
      </SiteSection>
      <SiteSection id='dernieres-nouveautes' margin='120px 0 0 0'>
        <HomeAds type='ad-sale' title='Nos derniers locaux à vendre' />
      </SiteSection>
      <SiteSection id='vendre-avec-un-expert' margin='120px 0 0 0'>
        <SellExperts />
      </SiteSection>
      <SiteSection id='tous-nos-locaux-a-vendre' margin='120px 0 0 0'>
        <Categories title='Tous nos locaux professionnels à vendre' categories={sellCategories} />
      </SiteSection>
      <SiteSection id='deleguer-vendre' margin='50px 0 80px 0'>
        <SellDelegate />
      </SiteSection>
      {/* <SiteSection id='avis' margin='60px 0 80px 0 '>
        <HomeReviews title='Ils nous ont fait confiance' />
      </SiteSection> */}
      <Footer />
    </>
  )
}

SellPage.propTypes = {
  location: PropTypes.object.isRequired
}

export default SellPage
