import React from 'react'

import WhiteBlock from '../WhiteBlock'

const PurchaseSteps = () => {
  return (
    <WhiteBlock
      title='Vendre votre local avec l’aide d’un expert'
      content='Le marché des locaux pour les professions libérales est très spécifique et demande une connaissance importante de l’immobilier local.<br/>
      Se faire accompagner par un agent expert de l’immobilier professionnel est donc un atout indéniable pour la réussite de votre vente.<br/>
      Les agents de Trouver Mon Local Pro pourront vous faire gratuitement une estimation de votre local avant que vous puissiez décider de la mise en vente de votre bien.'
      subtitle='Il n’est pas simple de s’occuper de vendre son propre local professionnel.'
      button={{
        link: '/nos-annonces/demande-accompagnement',
        title: 'Faites estimer votre local'
      }} />
  )
}

export default PurchaseSteps
