import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'
import { useStaticQuery, graphql } from 'gatsby'

import media from '../../../utils/media'
import Bullets from '../../atoms/Bullets'
import LaunchButton from '../../atoms/LaunchButton'
import ImageSection from '../../molecules/ImageSection'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const BULLETS = [
  'Obtenez une estimation précise en 48h',
  'Rencontrez votre expert dédié',
  'Mise en avant de votre bien auprès de milliers d’acheteur de locaux professionnels',
  'Prise en charge des visites',
  'Négociation avec les acheteurs',
  'Signature de la vente et gestion administrative'
]

const StyledBullets = styled(Bullets)`
  margin: 20px 0 20px 0;
`

const PlainText = styled.p`
  font-weight: 400;
  margin-top: 8px;
`

const CustomSubtitle = styled.p`
  font-family: 'Source Sans Pro';
  margin: 16px 0;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  color: ${({ theme }) => get(theme, 'blue', '#FFF')};

  ${media.lessThan('sm')`
    font-size: 16px;
  `}

  & > em {
    font-style: italic;
  }
`

const SellDelegate = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "sell/serein.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <ImageSection
      alt='Déléguez pour vendre sereinement'
      fixed={false}
      right={false}
      imageData={placeholderImage}
      maxText={580}>
      <HomeSectionTitle tag='h2'>
        Déléguez pour vendre sereinement
      </HomeSectionTitle>
      <CustomSubtitle>
        Nos agents sont là pour concrétiser votre projet de vente immobilière.<br />
        <PlainText>Pour cela, ils seront présents à toutes les étapes de la transaction&nbsp;:</PlainText>
        <StyledBullets bullets={BULLETS} />
      </CustomSubtitle>
      <HomeSectionDescription>
        La satisfaction de nos clients est la priorité de nos agents.<br />
        C’est de cette façon que nous pouvons continuer à obtenir des recommandations de la part de tous nos clients vendeurs et acquéreurs.<br />
        Si vous souhaitez vendre votre local professionnel sereinement et au meilleur prix, contactez dès maintenant un de nos experts.
      </HomeSectionDescription>
      <ButtonContainer>
        <LaunchButton
          link='/nos-annonces/demande-accompagnement'
          title='Échangez avec un expert'
          background='brightOrange' />
      </ButtonContainer>
    </ImageSection>
  )
}

export default SellDelegate
